export const memoize = <In extends string, Out>(
  func: (arg: In) => Out
): ((arg: In) => Out) => {
  const cache: Record<In, Out> = Object.create(null);
  return (arg) => {
    if (!cache[arg]) {
      cache[arg] = func(arg);
    }
    return cache[arg];
  };
};
