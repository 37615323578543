
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledIllustration_1a6e5caa98307821{max-width:400px;margin:0 auto}`;
styleInject(_css)

/** StyledIllustration Props */
export type StyledIllustrationCCM = {
  /** StyledIllustration Component Custom Properties */
  // No custom properties found

  /** StyledIllustration Modifier Flags */
  // No modifiers classes found
};
/** Base StyledIllustration component */
export const StyledIllustration: ComponentCreator<StyledIllustrationCCM> = createComponentCreator({
  "name": "StyledIllustration",
  "base": "StyledIllustration_1a6e5caa98307821",
  "prop": {},
  "mod": {}
});

