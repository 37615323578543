import {
  createElement,
  FC,
  lazy,
  Fragment,
  ReactNode,
  useMemo,
  CSSProperties,
  useCallback
} from 'react';
import { useIllustrationTheme } from './illustration.theme';
import { StyledIllustration } from './illustration.ccm.css';
// This file is dynamically generated, so we can ignore an editor warning if preset
import { IllustrationName } from './illustrations';
import { memoize } from './memoize';

export { IllustrationName };

export interface IllustrationProps {
  /**
   * The illustration to use
   */
  type: IllustrationName;
  /**
   * The label. This is added as the <title> inside the SVG, not visible but important for a11y
   */
  label: string;
}

const loadIllustration = async (
  illustration: IllustrationName
): Promise<{ default: string }> => {
  try {
    // Load the file relying on the importmap to resolve based on the svg name
    return await import(
      /* rollupIgnoreUnsafeDynamicImport */ './' + illustration + '.svg.js'
    );
  } catch (err) {
    // Safeguard and load the un-performant way using the loading file
    const { svgs } = await import('./illustrations');
    return svgs[illustration]();
  }
};

type RenderIllustrationComponent = FC<{ children: (mod: string) => ReactNode }>;

const getIllustrationComponent = memoize(
  (name: IllustrationName): RenderIllustrationComponent =>
    lazy(async () => {
      const { default: mod } = await loadIllustration(name);
      const Component: RenderIllustrationComponent = ({ children }) => (
        <Fragment>{children(mod)}</Fragment>
      );
      return { default: Component };
    })
);

const useIllustrationThemeColorProps = () => {
  const themeColors = useIllustrationTheme();
  return useMemo(
    () =>
      Object.entries(themeColors).reduce(
        (acc, [palletteName, colors]) =>
          Object.entries(colors).reduce((acc: any, [colorName, color]) => {
            acc[
              `--illustration-color-${palletteName}-${colorName}`
            ] = color as string;
            return acc;
          }, acc),
        {} as CSSProperties
      ),
    [themeColors]
  );
};

export const Illustration: FC<IllustrationProps> = ({ type, label }) => {
  const colorProps = useIllustrationThemeColorProps();
  // This is memoized globally so we don't enter Suspense states if the component
  // is already ready. Also, means we don't mind running this function on
  // each render
  const RenderIllustration = getIllustrationComponent(type);
  const onRefSet = useCallback(
    (span: HTMLSpanElement | null) => {
      const svg = span?.querySelector('svg');
      if (svg) {
        const title = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'title'
        );
        title.textContent = label;
        svg.prepend(title);
      }
    },
    [label]
  );

  return (
    <StyledIllustration.div>
      <RenderIllustration>
        {(__html) => (
          // We put all the color props directly on the span,
          // these are not isolated or hashed, these props are
          // global, but we are putting them only directly above
          // an inline SVG
          <span
            style={colorProps}
            dangerouslySetInnerHTML={{ __html }}
            ref={onRefSet}
          ></span>
        )}
      </RenderIllustration>
    </StyledIllustration.div>
  );
};
