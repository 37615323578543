import { createUseThemeHook, LegoColorPalette } from 'legos/theme';

// Augment the global BulkLegoTheme interface to support our lego theme
declare module 'legos/theme' {
  interface BulkLegoTheme {
    illustration: Partial<IllustrationTheme>;
  }
}

export interface IllustrationTheme {
  default: LegoColorPalette;
  special: LegoColorPalette;
  neutral: LegoColorPalette & { color0: string };
  positive: LegoColorPalette;
}

const [IllustrationThemeProvider, useIllustrationTheme] = createUseThemeHook(
  'illustration',
  (global): IllustrationTheme => ({
    default: { ...global.default },
    special: { ...global.special },
    neutral: {
      ...global.neutral,
      color0: '#FFFFFF'
    },
    positive: { ...global.positive }
  })
);

export { IllustrationThemeProvider, useIllustrationTheme };
